@media print {
  div {
    break-inside: avoid;
  }

  .sectionAuto {
    page-break-after: auto;
  }

  .sectionAlways {
    page-break-after: always;
  }

  .sectionBeforeAuto {
    page-break-before: auto;
  }

  .sectionBeforeAlways {
    page-break-before: always;
  }
}